
.ceo-section {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    background-color: rgba(255, 255, 255, 0);
    padding: 10%;
    flex-wrap: wrap;
}

.ceo-box {
    display: flex;
    flex-direction: row;
    background-color: rgb(5, 39, 78);
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    border-radius: 15px ;
    width: 30%;
    min-width: 300px;
    padding-top: 20px;
    min-height: 500px;
    margin-bottom: 30px;
    margin-left: 10px;
    margin-right: 10px;
}

.ceo-section h1{
    width: 90%;
    margin-top:20px;
    margin-bottom:20px;
    font-family: 'Barlow-Regular';
    font-size: 25px;
    text-align: center;
    align-self: center;    
    color: azure;
}

.ceo-section h2{
    width: 70%;
    margin-bottom:20px;
    font-family: 'Barlow-Regular';
    font-size: large;
    text-align: center;
    align-self: center;    
    color:rgb(237, 245, 245);
}

.ceo-box-img{
    margin-top:15px;
    margin-bottom:50px;
    position: relative; width: 200px; height: 200px; overflow: hidden; border-radius: 50%; 
    border-style: solid;
    border-width: 2px;
    border-color: rgb(29, 221, 228);
}

.ceo-box img{
    width: 100%; height: auto;
}


.ceo-section p{
    width: 70%;
    font-weight: bold;
    font-family: 'Barlow-thin';
    font-size: 14px;
    align-self: center;
    text-align: center;
    color:azure;
}

.ceo-section p2{
    width: 70%;
    font-weight: bold;
    font-family: 'Barlow-thin';
    font-size: 14px;
    align-self: center;
    text-align: center;
    color:azure;
}

.ceo-section div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ceo {
    padding-bottom: 20px;
}

.aboutus-container{
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    height: 100vh;
}

.about-us-white-sec{
    background-color: rgb(255, 255, 255);
    width: 100%;
}

.about-us-first-sec-container {
    position: relative;
    display: flex;
    justify-content: center;
    margin-top:  70px;
    /* transform: translate(-50%, -50%); */
}

.about-us-first-sec-container img{
   width: 50%;
   animation: fadeIn 5s; 
}


@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

.about-us-second-sec-container {
    position: relative;
    padding-top: 100px;
    padding-bottom: 100px;
    flex-wrap: wrap;
    /* transform: translate(-50%, -50%); */
    display: flex;
    justify-content: center;
}

.about-us-box-content{
    padding-top: 50px;
    padding-bottom: 50px;
    width: 60%;
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    border-radius: 20px ;
    background-color: rgb(16, 82, 161);
    margin-bottom: 10%;
}

.ceo-wrapper{
    object-fit: cover;
}

.ceo-bg{
    position: absolute;
    max-height: 1500px;
    min-height: 800px;
    transform: translate(0%, -95%);
    width: 100%;
    object-fit: cover;
    z-index: -1;
}

.about-us-box-content h1{
    width: 70%;
    font-family: 'Barlow-Regular';
    font-size: 30px;
    text-align: center;
    align-self: center;    
    color:azure;
}

.about-us-box-content p{
    width: 70%;
    font-weight: bold;
    font-family: 'Barlow-thin';
    font-size: 18px;
    align-self: center;
    text-align: center;
    color:azure;
}

.about-us-box-content2{
    padding-top: 50px;
    padding-bottom: 50px;
    width: 60%;
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    border-radius: 20px ;
    background-color: rgb(16, 82, 161);
    margin-bottom: 10%;
}
.about-us{
    position: absolute;
    top: 0;
    padding-top: 63.87px;
    width: 100%;
}


.about-us-box-content2 h1{
    width: 70%;
    font-family: 'Barlow-Regular';
    font-size: 30px;
    text-align: center;
    align-self: center;    
    color:azure;

}

.about-us-box-content2 p{
    width: 70%;
    font-weight: bold;
    font-family: 'Barlow-thin';
    font-size: 18px;
    align-self: center;
    text-align: justify;
    color:azure;
}

@media screen and (max-width: 414px) {
    .about-us-box-content{
        width: 200px;
        padding: 0;
        height: 180px;
    }
    .ceo-box {
        height: 250px;
    }
    .about-us-box-content h1{
        font-size: 14px;
    }
    .ceo-section h1 {
        font-size: 25px;
    }
    .ceo-section p {
        font-size: 16px;
    }
    .about-us-box-content p{
        width: 70%;
        font-size: 8px;
    }

    .about-us-box-content2 h1{
        font-size: 14px;
    }
    
    .about-us-box-content2 p{
        width: 70%;
        font-size: 10px;
    }
    .about-us-box-content p {
        font-size: 10px;
    }

    .ceo-box {
        min-width: 250px;
    }
    
    .ceo-section h1{
        width: 85%;
        font-size: 20px;
    }
    
    .ceo-section h2{
        font-size: large;
    }
    
    .ceo-box-img{
        position: relative; width: 150px; height: 150px; overflow: hidden; border-radius: 50%; 
    }
    
    .ceo-box img{
        width: 100%; height: auto;
    }

    .ceo-section p2{
        font-size: 12px;
    }
}