.footer-sec {
    width: 100%;
    display: flex;
    height: fit-content;
  }

.footer-sec img {
    width: 100%;
    object-fit: fill;
    
}

.footer-container{
    width: 100%;
    position: absolute;
    padding: 0;
    height: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: 20px;
    flex-wrap: nowrap;
    align-items: flex-start;
}

.footer-container2{
    width: 100%;
    position: relative;
    padding: 0;
    height: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: black;
    gap: 20px;
    flex-wrap: nowrap;
    bottom: 0;
}

.footer-container2 p{
    font-family: 'Barlow-Regular';
    font-size: 10px;
    text-align: center;
    align-self: center;    
    color: azure;
    padding-top: 10px;
    padding-bottom: 20px;
}

.footer-link{
    font-family: 'Barlow-Regular';
    font-size: 10px;
    text-align: center;
    align-self: center;    
    color: azure;
    padding-top: 10px;
    padding-bottom: 20px;
}
.footer-left-container{
    display: flex;
    flex-direction: row;
    width: 50%;
   position: relative;
}

.footer-left-container img{
    width: 20%;
    height: 20%;
    padding-right: 5%;
    padding-top: 2%;
}

.footer-left-container h1{
    display: flex;
    position: relative;
    font-weight: 800;
    font-family: 'Barlow';
    font-style: 'semi-condense-bold';
    font-size: 50px;
    align-self: center;
    color: whitesmoke;
}

.footer-left-container p{
    font-weight: 100;
    font-family: 'Barlow';
    font-style: 'condense-medium';
    font-size: 20px;
    align-self: center;
    color: whitesmoke;
}



 .footer-right-content {
    display: flex;
    font-weight: 100;
    font-family: 'Barlow';
    font-style: 'condense-medium';
    color: whitesmoke;
    gap: 10px;
    font-size: 20px;
 }


 .footer-right-content img{
    width: 25px;
    height: 25px;
    padding-right: 5%;
 }

 @media screen and (max-width: 768px) {
    .footer-left-container h1{
        font-size: 200%;
    }
    
    .footer-left-container p{
        font-size: 80%;
    }

    .footer-sec {
        height: 200px;
    }
  

    .footer-right-content {
        font-size: 14px;
    }

    .footer-right-content img{
        width: 18px;
        height: 18px;
    }
}


@media screen and (max-width: 375px) {
    .footer-left-container {
        width: 50%;

    }
    .footer-left-container h1{
        font-size: 18px;
    }
    
    .footer-left-container p{
        font-size: 10px;
    }


    .footer-sec {
      height: 180px;
    }

    .footer-right-content {
        width: 50%;
        font-size: 8px;
    }

    .footer-right-content img{
        width: 10px;
        height: 10px;
    }
}