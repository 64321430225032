@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    margin: 0;
    padding: 0;
    padding-right: 0;
}

#slider-wrapper{
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1;
}

#slider {
    display: flex;
    top: 0;
    z-index: 1;
    align-items: flex-start;
    height: 100vh;
    width: 300px;
    justify-content: flex-start;
    transform: translateX(100%);
    -webkit-transform: translateX(100%);
    font-family: 'Barlow';
    font-style: 'Barlow-thin';
    justify-content: flex-end;
}

.slide-in {
    background: rgb(255, 255, 255);
    width: 30%;
    box-shadow: rgb(0 0 0 / 12%) 0px 1px 3px, rgb(0 0 0 / 24%) 0px 1px 2px;
    animation: slide-in 0.5s forwards;
    -webkit-animation: slide-in 0.5s forwards;
}

.slide-out {
    background: rgb(255, 255, 255);
    width: 30%;
    box-shadow: rgb(0 0 0 / 12%) 0px 1px 3px, rgb(0 0 0 / 24%) 0px 1px 2px;
    animation: slide-out 0.5s forwards;
    -webkit-animation: slide-out 0.5s forwards;
}
    
@keyframes slide-in {
    100% { transform: translateX(0%); }
}

@-webkit-keyframes slide-in {
    100% { -webkit-transform: translateX(0%); }
}
    
@keyframes slide-out {
    0% { transform: translateX(0%); }
    90% {height: 100vh;}
    100% { transform: translateX(100%);
    height: 0; }
}

@-webkit-keyframes slide-out {
    0% { -webkit-transform: translateX(0%); }
    90% {height: 100vh;}
    100% { -webkit-transform: translateX(100%);  height: 0; }
}

.navSide-list li {
    list-style: none;
    padding: 26px 30px;
    display: flex;
    justify-content: flex-end;
    padding-right: 50px;
}
 
.navSide-list li a {
    text-decoration: none;
    color: #000;
}
 
.navSide-list li a:hover {
    color: grey;
}
 
.navbar {
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    z-index:1 ;
}
 
.background {
    background: rgb(255, 255, 255);
    background-blend-mode: darken;
    width: 100%;
    background-size: cover;
}
 
.footer {
background-color: #000;
}
 
.nav-list {
    width: 64%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-end;
}
 
.logo {
    display: flex;
    justify-content: center;
    align-items: center;
}
 
.logo img {
    width: 180px;
    border-radius: 50px;
}
 
.nav-list li {
    font-family: 'Barlow';
    font-style: 'Barlow-thin';
    list-style: none;
    padding: 24px 22px;
}
 
.nav-list li a {
    text-decoration: none;
    color: #000;
}
 
.nav-list li a:hover {
    color: grey;
}
 
.logotNav {
    width: 25%;
    text-align: left;
}

.headerLogo {
  width: 200px;
}
 
#search {
    padding: 5px;
    font-size: 17px;
    border: 2px solid rgb(0, 0, 0);
    border-radius: 9px;
}
 
.box-main {
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    max-width: 80%;
    margin: auto;
    height: 80%;
}
 
.firsthalf {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
 
.secondhalf {
    width: 30%;
}
 
.secondhalf img {
    width: 70%;
    border: 4px solid white;
    border-radius: 150px;
    display: block;
    margin: auto;
}
 
.text-big {
    font-weight: 500;
font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 30px;
}
 
.text-small {
    font-size: 18px;
}
 
.btn {
margin-left: 20px;
height: 33px;
width: 70px;
    color: #fff;
background-color: #000;
    cursor: pointer;
}
 
.btn-sm {
    padding: 6px 10px;
    vertical-align: middle;
}
 
.section {
    height: 200px;
    display: flex;
    align-items: center;
    background-color: rgb(250, 250, 250);
    justify-content: space-between;
}
 
.section-Left {
    flex-direction: row-reverse;
}
 
.center {
    text-align: center;
}

@media screen and (max-width: 971px) {
    .nav-list li{
        display:none;
      }
}

@media screen and (min-width: 970px) {
    .hamburger-react{
        display:none;
      }
    .navSide-container{
        display:none;
    }
    #slider{
        display:none;
    }
}

.disabled {
    pointer-events:none; 
    opacity:0.6;       
}

/* .footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: whitesmoke;
    color: #00469c;
    text-align: center;
  } */

  
/* FONT */
@font-face{
    font-family: 'Barlow-semi-condense-bold';
    src: url('./asset/fonts/barlow/BarlowSemiCondensed-Bold.otf') format('opentype');
}

@font-face{
    font-family: 'Barlow-condense-medium';
    src: url('./asset/fonts/barlow/BarlowCondensed-Regular.otf') format('opentype');
}

@font-face{
    font-family: 'Barlow-Regular';
    src: url('./asset/fonts/barlow/Barlow-Regular.otf') format('opentype');
}

@font-face{
    font-family: 'Barlow-semi-bold';
    src: url('./asset/fonts/barlow/Barlow-SemiBold.otf') format('opentype');
}

@font-face{
    font-family: 'Barlow-thin';
    src: url('./asset/fonts/barlow/Barlow-Thin.otf') format('opentype');
}
@font-face{
    font-family: 'Barlow-condense-black';
    src: url('./asset/fonts/barlow/BarlowCondensed-Black.otf') format('opentype');
}