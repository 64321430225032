.slider-container{
    display: flex;
    width: 100%;
}

.second-wraper{
    height: 600px;
    width: 100%;
}

.second-container-buy {
    display: flex;
    flex-direction: column;
    height: 600px;
    width: 100%;
    align-items: center;
}

.second-container-content {
    display: flex;
    flex-direction: column;
    height: 600px;
    width: 100%;
    align-items: center;
    transform: translate(0, -550px);
}


.second-container-content h1{
    font-family: 'Barlow-semi-condense-bold';
    font-size: 50px;
    padding-bottom: 10px;
    color: #121417;
}

.second-container-content p{
    font-family: 'Barlow-semi-condense-bold';
    font-size: 20px;
    padding-bottom: 20px;
    color: #133f75;

}
.second-container-buy img{
    object-fit: cover;
    width: 100%;
    height: 600px;
}
.box-wrapper{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
    height: 100%;
    align-items: stretch;
    align-content: flex-start;
}

.buynow-box{
    display: flex;
    flex-direction: column;
    flex-direction: column;
    justify-content: center;
    width: 15%;
    height: 30%;
    box-shadow: rgb(0 0 0 / 12%) 0px 1px 3px, rgb(0 0 0 / 24%) 0px 1px 2px;
    border-radius: 20px;
    background-color: rgb(16, 82, 161);
    align-items: center;
    font-family: 'Barlow-condense-medium';
    font-size: 100%;
    color: #ffffff;
    margin-left: 2%;
    margin-right: 2%;
    margin-bottom: 20px;
}

.buynow-box h{
    padding-bottom: 10px;
}
.icon-flag{
    width: 30%;
    min-width: 40px;
}
