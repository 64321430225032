.sub-paragraph{
    padding-left: 50px;
}

.line-break {
    white-space: pre-wrap;
  }
.markdown > * {
    white-space: 'pre';
  }

  table {
    border-spacing: 0 !important;
    border-collapse: collapse !important;
    border-color: black !important;
    display: block !important;
    width: max-content !important;
    max-width: 100% !important;
    overflow: auto !important;
}

tbody, td, tfoot, th, thead, tr {
    border-color: inherit !important;
    border-style: solid !important;
    border-width: 2px !important;
}