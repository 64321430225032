
.aesthetic-section{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding-top: 63.87px;
    width: 100%;
    flex-direction: row;
    font-family: 'Barlow-Regular';
    margin-bottom: 40px;
}
