.home-page{
    display: flex;
    position: absolute;
    top: 0;
    padding-top: 63.87px;
    width: 100%;
}
.home-container{
    position: relative;
    width: 100%;
    height: 100vh;
}

.home-section{
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    position: relative;
    width: 100%;
    object-fit: cover;    

}

.home-section img {
    position: relative;
    width: 100%;
    object-fit: cover;    
}

.text-container{
    position: absolute;  
    left: 50%;                        /* horizontal alignment */
    top: 50%;                         /* vertical alignment */
    transform: translate(-50%, -50%);
    height: 500px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;;
    justify-content: center;
    gap: 20px;
    text-shadow: 0px 2px 4px rgba(0,0,0,0.72);
}
.text-container div h1 {
    transform: translate(-20%,0%);
    font-family: 'Barlow';
    font-weight: 'semi-condense-bold';
    font-size: 40px;
    align-self: center;
    color: whitesmoke;
}

.text-container div p {
    font-family: 'Barlow';
    font-weight: 'semi-condense-bold';
    font-size: 20px;
    align-self: center;
    color: whitesmoke;

}

.parter-join-us-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.partner-container{
    position: absolute;
    transform: translate(-50%, -100%);
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    align-items: center;
    flex-wrap: nowrap;
}

.partner-container h1 {
    font-weight: 800;
    position: relative;
    font-family: 'Barlow';
    font-style: 'semi-condense-bold';
    font-size: 40px;
    align-self: center;
    color: #00469c;
}

.partner-sub-container img{
    padding-left: 5%;
    padding-right: 5%;
    width: 35%;
    max-width: 400px;
    height: auto;
    position: relative;
    object-fit: contain;
}

.partner-sub-container{
    display: flex;
    min-width: 410px;
    width: 100%;
    height: auto;
    position: relative;
    justify-content: center;
    z-index: -1;
    flex-direction: row;
    flex-wrap: wrap;
}

.join-us-container{
    position: relative;  
    transform: translate(0, -20%);
    height: 300px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;;
    justify-content: center;
    gap: 20px;
}

.join-us-container div img{
    position: relative;
    width: 90%;
}

@media screen and (max-width: 414px) {

    .partner-container h1 {
        font-size: 18px;
    }

    .text-container div h1 {
        font-size: 20px;
    }
    
    .text-container div p {
        font-size: 10px;
    }
    
    .join-us-container div img{
        position: relative;
        width: 300px;
    }
    .partner-container{
        width: 70%;
        font-size: 8px;
    }
}